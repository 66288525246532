
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import moment from "moment";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "invoice-download-modal",
  components: {
    Field,
    Multiselect,
  },
  props: {
    url: String,
    ListSchool: Array,
    schoolId: String,
    paymentStatus: String,
    role: String,
  },
  emits: ["gantiStatus", "pilihSekolah"],
  data() {
    return {
      isChecked: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      dateRange: [],
      status: this.paymentStatus,
    };
  },
  methods: {
    pilihSekolah(val) {
      this.$emit("pilihSekolah", val);
    },
    gantiStatus(event) {
      let val = event.target.value;
      console.log(val);
      this.$emit("gantiStatus", val);
    },
    download() {
      console.log("download", this.url);
      const toast = useToast();
      if (!this.dateRange[0] && !this.dateRange[1]) return toast.error("");
      ApiService.getDownload(
        `${this.url}?schoolId=${this.schoolId}&dateFrom=${moment(
          this.dateRange[0]
        ).format("YYYY-MM-DD")}&dateUntil=${moment(this.dateRange[1]).format(
          "YYYY-MM-DD"
        )}&paymentStatus=${this.paymentStatus}`
      )
        .then((res) => {
          this.handleBlobResponse(res.data, "Data Tagihan", "xlsx");
        })
        .catch((e) => {
          toast.error("");
        });
    },
    handleBlobResponse(blobFileData, newNama, extension) {
      const url = window.URL.createObjectURL(new Blob([blobFileData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", newNama + "." + extension); // nama file dan extension sesuaikan dengan file yang di download
      document.body.appendChild(link);
      link.click();
    },
  },
});
